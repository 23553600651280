// トップページのjs
import { mq } from "../jsmodules/_mediaQuery";

import "../sass/page-top.scss";

const mvVideoFn = () => {
	const mv = document.getElementById("js-mv");
	const video = document.getElementById("js-mv-video");
	const source = document.getElementById("js-mv-source");
	const button = document.getElementById("js-mv-onsei");

	if (!mv && !video && !button && !source) return;
	const CLASS_NAME_MUTED = "is-muted";

	// video の属性に値を代入
	const { posterPc, posterSp } = video.dataset;
	video.poster = mq("pc") ? posterPc : posterSp;

	// source の属性に値を代入
	// prettier-ignore
	const {
		srcPc,
		srcSp,
		widthPc,
		widthSp,
		heightPc,
		heightSp,
	} = source.dataset;
	source.width = mq("pc") ? widthPc : widthSp;
	source.height = mq("pc") ? heightPc : heightSp;
	source.src = mq("pc") ? srcPc : srcSp;

	video.load();

	video.play().catch((error) => {
		console.error("Error attempting to play the video:", error);
	});

	button.addEventListener("click", () => {
		if (mv.classList.contains(CLASS_NAME_MUTED)) {
			// ミュートであれば
			video.muted = false;
			mv.classList.remove(CLASS_NAME_MUTED);
		} else {
			// ミュートでなければ
			video.muted = true;
			mv.classList.add(CLASS_NAME_MUTED);
		}
	});
};

const showLogoFn = () => {
	const mv = document.getElementById("mv");

	if (!mv) return;
	const { body } = document;
	const CLASS_NAME_SHOW = "show-logo";

	const observer = new IntersectionObserver(
		(entries) => {
			for (let i = 0; i < entries.length; i += 1) {
				if (entries[i].isIntersecting) {
					body.classList.remove(CLASS_NAME_SHOW);
				} else {
					body.classList.add(CLASS_NAME_SHOW);
				}
			}
		},
		{
			root: null,
			rootMargin: "0% 0% -20% 0%",
			threshold: 0,
		},
	);

	observer.observe(mv);
};

const changeBGFn = () => {
	const bgs = document.querySelectorAll(".js-bg");
	const triggers = document.querySelectorAll(".js-bg-trigger");
	if (triggers.length === 0 || bgs.length === 0) return;
	const CLASS_NAME_SHOW = "is-shown";

	const observer = new IntersectionObserver(
		(entries) => {
			for (let i = 0; i < entries.length; i += 1) {
				const { targetid } = entries[i].target.dataset;
				const target = document.getElementById(targetid);
				if (entries[i].isIntersecting) {
					target.classList.add(CLASS_NAME_SHOW);
				} else {
					target.classList.remove(CLASS_NAME_SHOW);
				}
			}
		},
		{
			root: null,
			rootMargin: "0% 0% -20% 0%",
			threshold: 0,
		},
	);

	triggers.forEach((trigger) => observer.observe(trigger));
};

document.addEventListener("DOMContentLoaded", () => {
	mvVideoFn();
});

// htmx完了ポーリング
const watchInterval = setInterval(() => {
	const { body } = document;
	if (body && body.classList.contains("htmx-settled")) {
		changeBGFn();
		showLogoFn();
		clearInterval(watchInterval);
	}
}, 100);
